const MySkills = () => {
  return (
    <div className="min-h-screen flex flex-col lg:flex-row items-center gap-y-10 mt-20 lg:mt-0 w-5/6 mx-auto"
         id="my-skills">
      <div className="flex flex-col text-white h-full lg:w-1/2 justify-center">
        <h1 className="text-4xl lg:text-6xl font-bold text-white">
          My Skills and Experience
        </h1>
        <p className="text-xl mt-5">
          I possess a strong foundation in programming concepts
          with a Bachelor's degree in Computer Science.
        </p>
        <p className="text-xl mt-5">
          My commercial experience includes working as a Full Stack Developer Intern at Unicorn company, creating PWAs
          using React, Node.js and MongoDB as a main stack.
          The co-workers mark my collaborative skills, passion for learning and excellent problem-solving skills to
          deliver high-quality software solutions.
        </p>
        <p className="text-xl mt-5">
          On top of that, I have experience working in a team on projects at GR8 Tech, developing
          front-end applications, in particular, using the React.js and Redux libraries.
        </p>
        <p className="text-xl mt-5">
          Also, I do have several pet projects and one large project that can be described as a commercial -
          <a className="text-xl font-bold text-[#38bdf8]"
             href="https://travbase.net/"
             target="_blank">
            &nbsp;travbase.net.
          </a>
          <br/>
          <a className="text-[#38bdf8]"
             href="#projects">
            More in the projects section.
          </a>
        </p>
        <div className="text-xl mt-5">
          <p>I have finished several courses in the past as well:</p>
          <ul className="flex flex-col gap-1">
            <li className="hover:text-[#38bdf8]"><a
              href="https://www.coursera.org/account/accomplishments/verify/8CCSG2NUCW3D"
              target="_blank">
              2022 Coursera platform, Front-End Web Development with React
            </a></li>
            <li className="hover:text-[#38bdf8]"><a
              href="https://www.freecodecamp.org/certification/offtee/responsive-web-design"
              target="_blank">
              2021 FreeCodeCamp platform, Responsive Web Design
            </a></li>
            <li className="hover:text-[#38bdf8]"><a
              href="https://www.freecodecamp.org/certification/offtee/javascript-algorithms-and-data-structures"
              target="_blank">
              2021 FreeCodeCamp platform, JS Algorithms and Data Structures
            </a></li>
          </ul>
        </div>
        <div className="text-2xl mt-5 font-bold text-[#38bdf8]">
          <a href="https://drive.google.com/file/d/1CMGstdjRBY71ibtA_yPF8PIupK08yqQA/view?usp=sharing"
             target="_blank">
            My Resume
          </a>
        </div>
      </div>
      <div
        className="flex flex-col text-white h-full w-full lg:w-1/2 justify-center
                text-2xl font-bold text-white items-baseline lg:items-center gap-5">
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">HTML/CSS</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="95"
                    max="100"/>
        </div>
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">UI Libraries</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="95"
                    max="100"/>
        </div>
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">JavaScript</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="92"
                    max="100"/>
        </div>
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">TypeScript</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="90" max="100"/>
        </div>
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">React</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="85"
                    max="100"/>
        </div>
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">Redux</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="85"
                    max="100"/>
        </div>
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">SQL/NoSQL</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="80"
                    max="100"/>
        </div>
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">ReactNative</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="77" max="100"/>
        </div>
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">Node.js</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="75"
                    max="100"/>
        </div>
        <div className="flex items-baseline lg:items-center w-full lg:w-fit gap-5 flex-col lg:flex-row">
          <div className="w-32">Jest</div>
          <progress className="progress progress-accent bg-white w-full lg:w-56 shrink-0" value="72"
                    max="100"/>
        </div>
      </div>
    </div>
  );
};

export default MySkills;