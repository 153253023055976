const AboutMe = () => {
  return (
    <div className="min-h-screen flex flex-col lg:flex-row items-center mt-20 lg:mt-0 w-5/6 mx-auto" id="about-me">
      <div className="flex flex-col text-white h-full lg:w-1/2">
        <h1 className="text-4xl lg:text-6xl font-bold">
          Me, Myself & I
        </h1>
        <p className="text-xl mt-5">
          My name is Artur and I am from <span className="ukraine">Ukraine</span>.
          I love web development and started learning it since 16 years.
        </p>
        <p className="text-xl mt-5">
          As a highly motivated and enthusiastic Full Stack Developer and Computer Science graduate,
          I possess a strong foundation in programming concepts and
          a solid understanding of front-end and back-end development technologies.
        </p><p className="text-xl mt-5">
        I am a reliable, responsible, hard-working person and highly motivated to pursue a chance to improve
        my skills, gain invaluable experience and work on ambitious projects with positive people within
        your company :)
      </p>
        <p className="text-xl mt-5">
          Furthermore, I have an Advanced level of English.
          Academic IELTS test, Overall Band Score – 7.
        </p>
      </div>
      <div className="flex flex-col text-white h-full lg:w-1/2 items-center my-10">
        <img className="mask border border-black rounded-xl h-52 w-52 lg:h-72 lg:w-72"
             src="https://lh6.googleusercontent.com/wBmGVCmd_qW3r1R7oFJqEY47rph-idCB4NbuSulkz6AmdYXm7b2YDMxfnLbM2NgJFqU=w2400"
             alt="me"/>
      </div>
    </div>
  );
};

export default AboutMe;