// @ts-ignore
import {WavyBackground} from "./ui/WavyBackground.tsx";

const Home = () => {
  return (
    <div className="min-h-screen flex flex-col relative justify-center" id="home">
      <WavyBackground
        className="flex flex-col text-white items-center"
      >
        <div className="flex flex-col items-center w-5/6 lg:w-fit">
          <h1 className="text-6xl lg:text-8xl font-bold">
            Hi, I am Artur
          </h1>
          <p className="text-2xl lg:text-4xl mt-5 sm:text-center">
            Full Stack Developer and Bachelor of Computer Science
          </p>
          <a
            className="btn bg-transparent hover:bg-transparent border-white hover:border-[#38bdf8] hover:text-[#38bdf8]
          capitalize mt-10 text-2xl w-full lg:w-fit px-10"
            href="#contact">
            Contact
          </a>
        </div>
      </WavyBackground>
    </div>
  );
};

export default Home;